<template>
  <div>
    <h3>Service Commissions</h3>
    <form @submit.prevent="update">
      <div class="row" v-if="!loading">
        <div
          class="col-md-4"
          v-for="(commission, i) in commissions"
          :key="`commission-${i}`"
        >
          <div class="card">
            <div class="card-body text-center">
              <h4>{{ commission.type }}</h4>
              <div class="form-group">
                <label>Commission Percentage</label>
                <input
                  type="text"
                  placeholder="Commission"
                  class="form-control"
                  v-model="commission.percentage"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="form-group">
            <button class="btn btn-primary">
              <i class="lni lni-save me-2"></i>Save
            </button>
          </div>
        </div>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: false,
      commissions: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/admin/commissions").then((response) => {
        this.commissions = response.data.commissions;
        this.loading = false;
      });
    },

    update() {
      this.loading = true

      this.$axios.post('/api/v1/admin/commissions', this.commissions).then(() => {
        this.loading = false
        this.$swal('Commissions updated')
      })
    }
  },
};
</script>
